import React, { Component } from "reactn";
import { withRouter, Redirect } from "react-router-dom";
import { getStaticUrl } from "js/config";
import Spinner from "js/react/components/Spinner";
import { getQueryVariables } from "js/core/utilities/utilities";
import * as gDrive from "js/core/utilities/gDrive";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import * as googleAuth from "js/core/oauth/googleAuth";
import getLogger, { LogGroup } from "js/core/logger";

import "css/g-drive.scss";

const logger = getLogger(LogGroup.GDRIVE);

class Dialog extends Component {
    render() {
        const { header, content, footnote, buttonText, onClick } = this.props;

        return (<div className="dialog">
            <div className="dialog-header">{header}</div>
            <div className="dialog-content">{content}</div>
            {footnote && <div className="dialog-footnote">{footnote}</div>}
            {buttonText && onClick && <div className="dialog-button-container">
                <button className="dialog-accept-button" onClick={onClick}>
                    {buttonText}
                </button>
            </div>}
        </div>);
    }
}

class UiHandler extends Component {
    action = null;
    ids = [];
    folderId = null;
    userId = null;

    state = {
        showErrorDialog: false,
        showGrantOfflineAccessDialog: false,
        errorMessage: null,
        shouldLogout: false,
        showLogoutButton: false,
        fetching: true
    };

    componentDidMount() {
        const { firebaseUser } = this.props;

        const stateText = getQueryVariables("state");

        if (!stateText) {
            this.setState({ fetching: false, showErrorDialog: true });
        }

        try {
            const { action, ids, folderId, userId } = JSON.parse(stateText);
            this.action = action;
            this.ids = ids;
            this.folderId = folderId;
            this.userId = userId;
        } catch (err) {
            logger.error(err, "[UiHandler] failed to parse state");
            this.setState({ fetching: false, showErrorDialog: true });
            return;
        }

        const googleProvider = firebaseUser.providerData.find(provider => provider.providerId === "google.com");
        if (!googleProvider) {
            this.setState({ shouldLogout: true });
            return;
        }

        if (googleProvider.uid != this.userId) {
            this.setState({ fetching: false, showLogoutButton: true, showErrorDialog: true, errorMessage: "Oops, looks like you logged in as a wrong user" });
            return;
        }

        this.grantOfflineAccessIfNeededAndPerformActions()
            .catch(err => {
                logger.error(err, "[UiHandler] grantOfflineAccessIfNeededAndPerformActions() failed");
                this.setState({ fetching: false, showErrorDialog: true });
            });
    }

    /**
     * Grants offline access and enables Google Drive integration (if the user hasn't done it yet) and performs actions (open or create)
     */
    async grantOfflineAccessIfNeededAndPerformActions() {
        const { firebaseUser } = this.props;
        const { hasOfflineAccess, isGDriveEnabled } = await gDrive.getState();

        try {
            if (!hasOfflineAccess) {
                await gDrive.grantOfflineAccess(firebaseUser.uid);
            }

            if (!isGDriveEnabled) {
                await gDrive.enable();
            }

            await this.performActions();
        } catch (err) {
            if (err instanceof googleAuth.GoogleAuthPopupBlockedError) {
                this.setState({ fetching: false, showGrantOfflineAccessDialog: true });
                return;
            }

            if (err instanceof googleAuth.GoogleAuthFlowWasInterruptedError) {
                this.setState({ fetching: false, showLogoutButton: false, showErrorDialog: true, errorMessage: "Oops, looks like you did not log in correctly" });
                return;
            }

            if (err.message === "Wrong user") {
                this.setState({ fetching: false, showLogoutButton: true, showErrorDialog: true, errorMessage: "Oops, looks like you logged in as a wrong user" });
                return;
            }

            throw err;
        }
    }

    /**
     * Grants offline access, enables Google Drive integration and performs actions (open or create)
     */
    onGrantOfflineAccessClick() {
        const { firebaseUser } = this.props;

        this.setState({ fetching: true, showGrantOfflineAccessDialog: false });

        gDrive.grantOfflineAccess(firebaseUser.uid)
            .then(() => gDrive.enable())
            .then(() => this.performActions())
            .catch(err => {
                logger.error(err, "[UiHandler] grantOfflineAccess() failed");
                if (err.status === 409) {
                    this.setState({ fetching: false, showErrorDialog: true, errorMessage: "Oops, looks like you logged in as a wrong user" });
                } else {
                    this.setState({ fetching: false, showErrorDialog: true });
                }
            });
    }

    /**
     * Opens or creates a presentation depending on the action parameter received from Google Drive
     */
    async performActions() {
        if (this.action === "create") {
            window.location = `/?gDriveFolderId=${this.folderId}`;
            return;
        }

        if (this.action === "open") {
            const urls = [];
            for (const fileId of this.ids) {
                const presentationUrl = await gDrive.getPresentationUrl(fileId);
                urls.push(presentationUrl);
            }

            urls.filter((url, idx) => idx > 0).forEach(url => window.open(url, "_blank"));
            window.location = urls[0];
        }
    }

    render() {
        const { showErrorDialog, showGrantOfflineAccessDialog, errorMessage, showLogoutButton, shouldLogout, fetching } = this.state;

        if (shouldLogout) {
            return <Redirect to={`/logout?continue=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`} />;
        }

        return (
            <div className="gdrive-ui-handler">
                <div className="layout">
                    <img className="logo" onClick={() => window.open("/?", "_blank")} src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} alt="logo" />
                    {fetching && <Spinner />}
                    {!fetching && showGrantOfflineAccessDialog &&
                        <Dialog
                            header="Google Drive Access"
                            content="Press the button to grant Beautiful.ai access to your Google Drive"
                            footnote="You will be redirected to the Google's consent page"
                            buttonText="Grant Access To Your Google Drive"
                            onClick={() => this.onGrantOfflineAccessClick()} />
                    }
                    {!fetching && showErrorDialog &&
                        <Dialog
                            header="Error"
                            content={errorMessage || "Sorry, we could not process your request"}
                            buttonText={showLogoutButton
                                ? "Log out"
                                : "Go Back to Google Drive"
                            }
                            onClick={showLogoutButton
                                ? () => this.setState({ shouldLogout: true })
                                : () => window.location = "https://drive.google.com"
                            }
                        />
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withFirebaseUser(UiHandler));
